import React from "react"
import { Table, message } from "antd"
import { navigate } from "gatsby"

import Layout from "../layouts"

import "./invitations.css"
import { getUserInvitationList } from "../api/user"
import { FIRST_PAGE, INVITATIONS_PAGE_SIZE, INVITATION_STATUS_LABEL } from "../utils/constant"
import UserAvatar from "../components/UserAvatar"

class InvitationList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      invitationList: [],
      total: 0,
    }
  }

  componentWillMount() {
    // 避免构建失败
    if (typeof window === "undefined" || typeof document === 'undefined') {
      return;
    }
    this.initInvitationList()
  }

  initInvitationList = async () => {
    const res = await getUserInvitationList(FIRST_PAGE, INVITATIONS_PAGE_SIZE)
    const data = res.data.data
    if (data) {
      const { invitationList, pageNum } = data
      this.setState({ invitationList, total: pageNum })
    } else {
      message.error("加载邀请列表出错")
    }
  }

  onPaginationChange = async (page) => {
    const res = await getUserInvitationList(page, INVITATIONS_PAGE_SIZE)
    const data = res.data.data
    if (data) {
      const { invitationList, pageNum } = data
      this.setState({ invitationList, total: pageNum })
    } else {
      message.error("加载平台发布记录列表出错")
    }
  }

  render() {
    const { invitationList, total } = this.state

    const columns = [
      {
        title: "用户名",
        dataIndex: "username",
        key: "username",
      },
      {
        title: "头像",
        dataIndex: "avatar",
        key: "avatar",
        render: (avatar, record) =>
          <UserAvatar avatar={record.avatar} username={record.username} size="default"/>,
      },
      {
        title: "邀请状态",
        dataIndex: "status",
        key: "status",
        render: status => {
          return INVITATION_STATUS_LABEL[status];
        },
      },
    ]
    return (
      <Layout isSpacerDisplay={true} title="邀请记录">
        <div className="mdnice-invitations-container">
          <div className="mdnice-invitations-table-container">
            <Table
              bordered
              columns={columns}
              dataSource={invitationList}
              pagination={{ onChange: this.onPaginationChange, total }}
            />
          </div>
        </div>
      </Layout>
    )
  }
}

export default InvitationList
